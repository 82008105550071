import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import * as userReducer from './user.reducer';

const createRootReducer = (history: History<any>) =>
	combineReducers(
		Object.assign(
			{ router: connectRouter(history) },
			userReducer
		),
	);
export default createRootReducer;
