import React from 'react';

const Logout: React.FC = (props: any) => {

	const clearSession = () => {
		localStorage.removeItem('id_token');
		localStorage.removeItem('uid');
		const url = window.location.pathname;
		sessionStorage.setItem('url', url);
	}
	
	(async () => {
		const params = new URLSearchParams(props.location.search);
		let logoutPath: any = '/';
		if (params.get('sessionTimeout')) {
			logoutPath = '/session-timeout';
		}
		if (params.get('authError')) {
			logoutPath = '/auth-error';
		}

		clearSession();
		window.location.href = logoutPath;
	})();

	return <div></div>;
};

export default Logout;
