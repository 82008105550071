import createReducer from './createReducer';
import { UserActions } from '../actions';
import { USER } from '../constants/actions';
import { isUndefined } from 'lodash';

export const user = createReducer(
	{},
	{
		[USER.SET_AUTH_USER](state = {}, action: ReturnType<typeof UserActions.setAuthUser>) {
			return { ...state, authUser: action.payload };
		},
		[USER.SET_CALLBACK_URL](state = {}, action: ReturnType<typeof UserActions.setCallbackUrl>) {
			return { ...state, callbackUrl: action.payload };
		},
		[USER.SET_AUTH_ERROR](state = {}, action: ReturnType<typeof UserActions.setAuthError>) {
			return { ...state, authError: action.payload };
		},
		[USER.SET_SNACKBAR_OPEN](state = {}, action: ReturnType<typeof UserActions.setSnackbarOpen>) {
			return { ...state, isSnackbarOpen: action.payload };
		},
		[USER.SET_SNACKBAR_MESSAGE](state = {}, action: ReturnType<typeof UserActions.setSnackbarMessage>) {
			return {
				...state,
				isSnackbarOpen: action.payload.message ? true : false,
				snackbarMessage: action.payload.message,
				snackbarStatus: action.payload.type || 'info',
				snackbarAutohide: isUndefined(action.payload.snackbarAutohide) ? 5000 : action.payload.snackbarAutohide,
			};
		}
	}, 
);
