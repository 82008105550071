export const USER = {
	SET_SNACKBAR_OPEN: 'SET_SNACKBAR_OPEN',
	SET_SNACKBAR_MESSAGE: 'SET_SNACKBAR_MESSAGE',
	SEND_USER_LOGIN_EMAIL: 'SEND_USER_LOGIN_EMAIL',
	VERIFY_ACCESS_CODE: 'VERIFY_ACCESS_CODE',
	SET_USER_PASSWORD: 'SET_USER_PASSWORD',
	GET_USER_AUTH_FLAGS: 'GET_USER_AUTH_FLAGS',
	SET_AUTH_USER: 'SET_AUTH_USER',
	SET_CALLBACK_URL: 'SET_CALLBACK_URL',
	SET_AUTH_ERROR: 'SET_AUTH_ERROR',
	REQUEST_CUSTOM_TOKEN: 'REQUEST_CUSTOM_TOKEN',
};

export const UI = {
	SET_CUSTOMER: 'SET_CUSTOMER',
	SET_SELECTED_DATE_RANGE: 'SET_SELECTED_DATE_RANGE',
	SET_SELECTED_CABINET_TYPE: 'SET_SELECTED_CABINET_TYPE',
	SET_SELECTED_EXPIRY_DATE: 'SET_SELECTED_EXPIRY_DATE',
	SET_SELECTED_HISTORY_DATE: 'SET_SELECTED_HISTORY_DATE',
	SET_SELECTED_TRANSMITTAL_DATE: 'SET_SELECTED_TRANSMITTAL_DATE',
	SET_MORE_FILTER_VALUE: 'SET_MORE_FILTER_VALUE',
	STORE_CUSTOMER: 'STORE_CUSTOMER',
	NAVIGATE: 'UI_NAVIGATE',
	NAVIGATE_RESPONSE: 'UI_NAVIGATE_RESPONSE',
	SHOW_LOADER: 'SHOW_LOADER',
	SET_INVENTORY_STATE: 'SET_INVENTORY_STATE',
	GET_INVENTORY_STATE: 'GET_INVENTORY_STATE',
	SET_API_ERROR: 'SET_API_ERROR',
	SET_NAV_DRAWER: 'SET_NAV_DRAWER',
	SET_URL_FILTER: 'SET_URL_FILTER',
	SET_NAV_STATE: 'SET_NAV_STATE',
	CLOUD_MGNT_LINK_LOADING: 'CLOUD_MGNT_LINK_LOADING',
	AUTH_PROCESSING: 'AUTH_PROCESSING',
	RESET_ALL_FILTERS: 'RESET_ALL_FILTERS',
	SET_GLOBAL_ROWS_PER_PAGE: 'SET_GLOBAL_ROWS_PER_PAGE',
	SET_SAVE_SEARCH_PAGE_NAME: 'SET_SAVE_SEARCH_PAGE_NAME',
	SET_SAVE_SEARCH_TITLE: 'SET_SAVE_SEARCH_TITLE',
	SET_SELECTED_SAVE_FILTER: 'SET_SELECTED_SAVE_FILTER',
	DISABLE_SERIAL_NUMBER_FILTER: 'DISABLE_SERIAL_NUMBER_FILTER',
	DISABLE_OWNERSHIP_FILTER: 'DISABLE_OWNERSHIP_FILTER',
};
