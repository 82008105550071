import React from 'react';
import {Button, FormControl, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { globalColors } from '../../hooks/styles/muiTheme';
import { UserActions } from '../../redux/actions';
import { connect } from 'react-redux';
import { emailRegex } from '../users/users.helper';
import Loading from '../../components/loading';
import { firebaseSignInWithCustomToken } from '../../redux/lib/firebase';
import { useHistory } from 'react-router-dom';

const PasswordlessLogin = (props: any) => {
  const history = useHistory();
  const [emailSent, setEmailSent] = React.useState(false);
  const [accessCode, setAccessCode] = React.useState('');
  const [formErrors, setFormErrors] = React.useState({ email: '', accessCode: '' });
  const [loading, setLoading] = React.useState(false);

  const handleSubmitLoginEmail = () => {
    if (!props.email || !emailRegex.test(props.email)) {
      return
    }
    setLoading(true);
    props.sendLoginEmail({
      email: props.email,
      success: () => {
        setEmailSent(true);
        setLoading(false);
      },
      error: () => {
        setLoading(false);
      }
    });
  };

  const handleSubmitVerifyAccessCode = () => {
    if (!props.email || !emailRegex.test(props.email) || !accessCode) {
      return
    }
    setLoading(true);
    props.verifyAccessCode({
      requestBody: { email: props.email, accessCode: accessCode },
      success: (customToken: string) => {
        handleAuthToken(customToken)
      },
      error: () => {
        setLoading(false);
      }
    });
  };

  const handleAuthToken = async (authToken: string) => {
    const loginResult = await firebaseSignInWithCustomToken(authToken)
    const idToken = await loginResult.user.getIdToken();
    props.setAuthUser(idToken);
    history.push('/password-reset');
  }

	return (
		<div style={{ textAlign: 'center' }}>
      <h2>Password Expired</h2>
      {!emailSent && <h4>You must reset your password. Please request a password reset access code for {props.email}.</h4>}
      {emailSent && <Alert  style={{ margin: '0.5em auto', width: '75%' }} variant='outlined' severity='success'>Access code sent to {props.email} Please check your email inbox.</Alert>}
      <form noValidate autoComplete="off">
        {!emailSent &&
          <>
            {loading ? 
              <Loading message='' />
            :
              <FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
                <Button
                  style={{ margin: '10px 0 0 0', backgroundColor: `${globalColors.FFF_BLUE} !important` }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitLoginEmail}
                >
                  REQUEST ACCESS CODE
                </Button>
              </FormControl>
            }
          </>
        }
        {emailSent && 
          <>
            <FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
              <TextField
                label="Access Code"
                variant="outlined"
                type="text"
                value={accessCode}
                onBlur={() => {
                  if (accessCode) {
                    setFormErrors({ ...formErrors, accessCode: '' });
                    return
                  }
                  setFormErrors({ ...formErrors, accessCode: 'Please enter an access code' });
                }}
                error={formErrors.accessCode ? true : false}
                helperText={formErrors.accessCode || ''}
                onChange={event => {
                  setAccessCode(event.target.value)
                }}
                required
              />
            </FormControl>
            {loading ? 
              <Loading message='' />
            :
              <FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
                <Button
                  type="submit"
                  style={{ margin: '10px 0 0 0', backgroundColor: `${globalColors.FFF_BLUE} !important` }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitVerifyAccessCode}
                >
                  SUBMIT
                </Button>
              </FormControl>
            }
          </>
        }
      </form>
      <p style={{color: 'red'}}>{/*loginError*/}</p>
    </div>
	);
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  sendLoginEmail: (payload: any) => dispatch(UserActions.sendLoginEmail(payload)),
  verifyAccessCode: (payload: any) => dispatch(UserActions.verifyAccessCode(payload)),
  setAuthUser: (payload: any) => dispatch(UserActions.setAuthUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordlessLogin);
