import local from './config/local.json';
import devAuth from './config/dev-auth.json';
import qaAuth from './config/qa-auth.json';
import demoAuth from './config/demo-auth.json';
import auth from './config/auth.json';


const configFiles: any = {
	'dev-auth': devAuth,
	'qa-auth': qaAuth,
	'demo-auth': demoAuth,
	'auth': auth

};

function getConfig() {
	// @ts-ignore
	const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

	if (!subdomain) {
		return local;
	}

	return configFiles[subdomain];
}

export default getConfig();
