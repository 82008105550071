import { takeLatest } from 'redux-saga/effects';
import {
	USER,
} from '../constants/actions';


import * as user from './user.saga';

export default function* rootSaga() {
	yield takeLatest(USER.SEND_USER_LOGIN_EMAIL, user.sendLoginEmail);
	yield takeLatest(USER.VERIFY_ACCESS_CODE, user.verifyAccessCode);
	yield takeLatest(USER.SET_USER_PASSWORD, user.setUserPassword);
	yield takeLatest(USER.GET_USER_AUTH_FLAGS, user.getUserAuthFlags);
	yield takeLatest(USER.REQUEST_CUSTOM_TOKEN, user.requestCustomToken);
}