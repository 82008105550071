import React from 'react';
import { Container, Paper } from '@material-ui/core';
import { IMAGES_URL } from '../../constants/Images';
import { connect } from 'react-redux';

const AuthError = (props: any) => {

	return (
		<Container component="main" maxWidth="xs">
			<Paper
				style={{
					marginTop: '2em',
					padding: '1.5em',
					textAlign: 'center',
				}}
			>
				<img src={IMAGES_URL.RNI_LOGO_SM} alt="FFF Logo" />
				<h2>We've encountered an authentication error.</h2>
				<p style={{color: 'red'}}>{props.authError}</p>
			</Paper>
		</Container>
	);
};

const mapStateToProps = (state: any) => ({
	authError: state.user.authError,
});

export default connect(mapStateToProps)(AuthError);
