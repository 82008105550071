import {initializeApp} from 'firebase/app';
import {
  getAuth, 
  signInWithCustomToken, 
  signInWithEmailAndPassword
} from 'firebase/auth';
import config from '../../config';

// Configure Firebase.
const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  // ...
};

initializeApp(firebaseConfig);
const auth = getAuth();

export const firebaseSignInWithEmailAndPassword = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
}

export const firebaseSignInWithCustomToken = async (customToken: string) => {
  return await signInWithCustomToken(auth, customToken);
}

export const firebaseRefreshIdToken = async () => {
  if (!auth.currentUser) {
    throw new Error('User is not signed in');
  }
  return await auth.currentUser.getIdToken(true);
}