// Import FirebaseAuth and firebase.
import React from 'react';
import { firebaseSignInWithEmailAndPassword } from '../../redux/lib/firebase';
import { IMAGES_URL } from '../../constants/Images';
import {
	Container,
	Paper,
	FormControl,
	TextField,
	Button,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { BasePageStyles } from '../../hooks/styles';
import { globalColors } from '../../hooks/styles/muiTheme';
import { emailRegex } from '../users/users.helper';
import Loading from '../../components/loading';
import PasswordExpired from './password-expired';
import { UserActions } from '../../redux/actions';
import { connect } from 'react-redux';
import config from '../../config';

function LoginScreen(props: any) {
  const classes = BasePageStyles();
	const paperStyles = {
		padding: '1em',
	};
  const [email, setEmail] = React.useState('');
	const [emailFocused, setEmailFocused] = React.useState(true)
  const [password, setPassword] = React.useState('');
  const [loginError, setLoginError] = React.useState('')
	const [loadingAuthFlags, setLoadingAuthFlags] = React.useState(false)
	const [authFlagsFetched, setAuthFlagsFetched] = React.useState(false)
	const [authFlags, setAuthFlags] = React.useState<string[]>([])

  const login = async() => {
		setLoginError('');
		if (authFlagsFetched) {
			try {
				setLoadingAuthFlags(true);
				const loginResult = await firebaseSignInWithEmailAndPassword(email, password)
				const token = await loginResult.user.getIdToken();	
				if (!token) {	
					throw new Error('Unable to retrieve token. Please try again.')
				}
				props.requestCustomToken({
					requestBody: { idToken: token},
					success: (customToken: string) => {
						window.location.href = `${props.callbackUrl}?authToken=${customToken}`
					},
					error: (error: any) => {
						setLoadingAuthFlags(false)
						let errMsg = error.message ? error.message : 'Unable to fetch auth token. Please try again.'
						throw new Error(errMsg)
					}
				});
			} catch (error: any) {
				let errMsg = error.message ? error.message : 'An error occurred. Please try again.'
				if (error.message && error.message.includes('auth')) errMsg = 'User email/password is invalid.'
				setLoginError(errMsg)
				setLoadingAuthFlags(false)
			}
			return
		}

		// if auth flags are not fetched, fetch them
		setLoadingAuthFlags(true)
		props.getUserAuthFlags({
			requestBody: {
				email: email
			},
			success: (authFlagsResult: string[]) => {
				setAuthFlags(authFlagsResult)
				setAuthFlagsFetched(true)
				setLoadingAuthFlags(false)
			},
			error: (error: any) => {
				setLoginError(JSON.stringify(error?.response?.data) || 'An error occurred. Please try again.')
				setLoadingAuthFlags(false)
			}
		})
  }

	const emailIsValid = () => {
    return (email && emailRegex.test(email))
  }

	const getLoginForm = () => {
		return (<>
			<h2>User Login</h2>
			<form noValidate>
				<FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
					<TextField
						autoComplete="email"
						label="Email"
						variant="outlined"
						type="text"
						value={email}
						onFocus={() => setEmailFocused(true)}
						onBlur={() => setEmailFocused(false)}
						error={!emailFocused && !emailIsValid() ? true : false}
						helperText={!emailFocused && !emailIsValid() ? 'Please enter a valid email' : ''}
						onChange={event => {
							setEmail(event.target.value)
						}}
						required
					/>
				</FormControl>
				{authFlagsFetched && <FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
					<TextField
						label="Password"
						variant="outlined"
						type="password"
						value={password}
						onChange={event => {
							setPassword(event.target.value)
						}}
						required
					/>
				</FormControl>}
				{!loadingAuthFlags ? 
					<FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
						<Button
							type="submit"
							style={{ margin: '10px 0 0 0', backgroundColor: `${globalColors.FFF_BLUE} !important` }}
							variant="contained"
							color="primary"
							onClick={login}
							disabled={authFlagsFetched ? (!emailIsValid() || !password) : !emailIsValid()}
						>
							SUBMIT
						</Button>
					</FormControl>
				: 
					<Loading message="" />
				}
			</form>
			{authFlagsFetched && <Link 
				className={classes.link} 
				to={`/passwordless-login?force-password-reset=true&email=${email}`}
			>
				Forgot Password?
			</Link>}
			{loginError && <p style={{color: 'red'}}>{loginError}</p>}
			{props.authTokenError && <p style={{color: 'red'}}>{props.authTokenError}</p>}
		</>)
	}

	const getAuthFlagOptions = () => {
		if (authFlags.includes('requirePasswordReset')) {
			return (
				<PasswordExpired email={email} />
			)
		
		}
	}

  return (
    <div>
      <Container maxWidth="sm" className={classes.container} style={{background: 'none'}}>
				<Paper style={paperStyles}>
					<img src={IMAGES_URL.RNI_LOGO} style={{ width: '50%', margin: '0 25%' }} alt="right now inventory" />
					{authFlags.length === 0 && <div style={{ textAlign: 'center' }}>
						{getLoginForm()}
          </div>}
					{authFlags.length > 0 && <div style={{ textAlign: 'center' }}>
						{getAuthFlagOptions()}
					</div>}
        </Paper>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
	callbackUrl: config.localCallbackUrl ? config.localCallbackUrl : state.user.callbackUrl
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserAuthFlags: (payload: any) => dispatch(UserActions.getUserAuthFlags(payload)),
	requestCustomToken: (payload: any) => dispatch(UserActions.requestCustomToken(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
