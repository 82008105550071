import { action } from 'typesafe-actions';
import { USER } from '../constants/actions';

export const setSnackbarOpen = (isOpen: boolean) => action(USER.SET_SNACKBAR_OPEN, isOpen);
export const setSnackbarMessage = (payload: object) => action(USER.SET_SNACKBAR_MESSAGE, payload);

export const setCallbackUrl = (payload: string) => action(USER.SET_CALLBACK_URL, payload);
export const setAuthError = (payload: string) => action(USER.SET_AUTH_ERROR, payload);

export const sendLoginEmail = (payload: string) => action(USER.SEND_USER_LOGIN_EMAIL, payload);
export const verifyAccessCode = (payload: string) => action(USER.VERIFY_ACCESS_CODE, payload);
export const setUserPassword = (payload: string) => action(USER.SET_USER_PASSWORD, payload);
export const getUserAuthFlags = (payload: string) => action(USER.GET_USER_AUTH_FLAGS, payload);
export const setAuthUser = (payload: string) => action(USER.SET_AUTH_USER, payload);
export const requestCustomToken = (payload: string) => action(USER.REQUEST_CUSTOM_TOKEN, payload);
