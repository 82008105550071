import React from 'react';
import { Container, Paper, Button, FormControl, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { IMAGES_URL } from '../../constants/Images';
import { globalColors } from '../../hooks/styles/muiTheme';
import { UserActions } from '../../redux/actions';
import { connect } from 'react-redux';
import { emailRegex } from '../users/users.helper';
import Loading from '../../components/loading';
import { BasePageStyles } from '../../hooks/styles';
import { useHistory } from 'react-router-dom';
import { firebaseSignInWithCustomToken } from '../../redux/lib/firebase';
import config from '../../config';

const PasswordlessLogin = (props: any) => {
  const classes = BasePageStyles();
  const paperStyles = {
		padding: '1em',
	};
  const history = useHistory();
  const queryStringParams = new URLSearchParams(window.location.search);
  const [email, setEmail] = React.useState(queryStringParams.get('email') || '');
  const [emailSent, setEmailSent] = React.useState(false);
  const [accessCode, setAccessCode] = React.useState('');
  const [formErrors, setFormErrors] = React.useState({ email: '', accessCode: '' });
  const [loading, setLoading] = React.useState(false);

  if (queryStringParams.get('force-password-reset')) {
    sessionStorage.setItem('force-password-reset', 'true');
  }

  const handleSubmitLoginEmail = () => {
    if (!email || !emailRegex.test(email)) {
      return
    }
    setLoading(true);
    props.sendLoginEmail({
      email: email,
      success: () => {
        setEmailSent(true);
        setLoading(false);
      },
      error: () => {
        setLoading(false);
      }
    });
  };

  const handleSubmitVerifyAccessCode = () => {
    if (!email || !emailRegex.test(email) || !accessCode) {
      return
    }
    setLoading(true);
    props.verifyAccessCode({
      requestBody: { email, accessCode },
      success: (customToken: string) => {
        handleAuthToken(customToken)
      },
      error: () => {
        setLoading(false);
      }
    });
  };

  const handleAuthToken = async (authToken: string) => {
		try {
      if (sessionStorage.getItem('force-password-reset')) {
          const loginResult = await firebaseSignInWithCustomToken(authToken)
          const idToken = await loginResult.user.getIdToken();
          props.setAuthUser(idToken);
          history.push('/password-reset');
          return;
      }
      window.location.replace(`${props.callbackUrl}?authToken=${authToken}`)
		} catch (error: any) {
			console.error('Invalid auth token', error.message);
			history.push('/auth-error');
		}
	}

	return (
		<Container maxWidth="sm" className={classes.container} style={{background: 'none'}}>
			<Paper style={paperStyles}>
				<img src={IMAGES_URL.RNI_LOGO} style={{ width: '50%', margin: '0 25%' }} alt="right now inventory" />
				<div style={{ textAlign: 'center' }}>
						<h2>Login Via Email</h2>
            {!emailSent && <h4>Submit your email address to request an access code.</h4>}
            {emailSent && <Alert  style={{ margin: '0.5em auto', width: '75%' }} variant='outlined' severity='success'>Access code sent! Please check your email inbox.</Alert>}
						<form noValidate autoComplete="off">
							{!emailSent && <FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
								<TextField
                  autoComplete='email'
									label="Email"
									variant="outlined"
									type="text"
									value={email}
                  onBlur={() => {
                    console.log(formErrors)
                    if (email && emailRegex.test(email)) {
                      setFormErrors({ ...formErrors, email: '' });
                      return
                    }
                    setFormErrors({ ...formErrors, email: 'Please enter a valid email address' });
                  }}
									error={formErrors.email ? true : false}
									helperText={formErrors.email || ''}
									onChange={event => {
										setEmail(event.target.value)
									}}
									required
								/>
							</FormControl>}
              {emailSent && <FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
								<TextField
									label="Access Code"
									variant="outlined"
									type="text"
									value={accessCode}
                  onBlur={() => {
                    if (accessCode) {
                      setFormErrors({ ...formErrors, accessCode: '' });
                      return
                    }
                    setFormErrors({ ...formErrors, accessCode: 'Please enter an access code' });
                  }}
									error={formErrors.accessCode ? true : false}
									helperText={formErrors.accessCode || ''}
									onChange={event => {
										setAccessCode(event.target.value)
									}}
									required
								/>
							</FormControl>}
              {loading ? 
                <Loading message='' />
              :
                <FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
                  <Button
                    type="submit"
                    style={{ margin: '10px 0 0 0', backgroundColor: `${globalColors.FFF_BLUE} !important` }}
                    variant="contained"
                    color="primary"
                    onClick={emailSent ? handleSubmitVerifyAccessCode : handleSubmitLoginEmail}
                  >
                    SUBMIT
                  </Button>
                </FormControl>
              }
            </form>
            <p style={{color: 'red'}}>{/*loginError*/}</p>
          </div>
			</Paper>
		</Container>
	);
};

const mapStateToProps = (state: any) => ({
  callbackUrl: config.localCallbackUrl ? config.localCallbackUrl : state.user.callbackUrl
});

const mapDispatchToProps = (dispatch: any) => ({
  sendLoginEmail: (payload: any) => dispatch(UserActions.sendLoginEmail(payload)),
  verifyAccessCode: (payload: any) => dispatch(UserActions.verifyAccessCode(payload)),
  setAuthUser: (payload: any) => dispatch(UserActions.setAuthUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordlessLogin);
