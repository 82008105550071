import { call, put } from 'redux-saga/effects';
import {
	UserActions,
} from '../actions/index';
import {
	UserService,
} from '../services';

export function* sendLoginEmail(action: ReturnType<typeof UserActions.sendLoginEmail>) {
	try {
		yield call(UserService.getInstance().sendLoginEmail, action.payload.email);
		action.payload.success();
	} catch (err: any) {
		action.payload.error();
		let message = 'Failed to send login email. Please try again.';
		if (err.response?.data) message = err.response.data;
		yield put(
			UserActions.setSnackbarMessage({
				message: message,
				type: 'error',
				snackbarAutohide: null,
			}),
		);
		console.log('saga error', err);
	}
}

export function* verifyAccessCode(action: ReturnType<typeof UserActions.verifyAccessCode>) {
	try {
		const { data } = yield call(UserService.getInstance().verifyAccessCode, action.payload.requestBody);
		action.payload.success(data.token);
	} catch (err) {
		action.payload.error();
		yield put(
			UserActions.setSnackbarMessage({
				message: 'Failed to verify access code. Please try again.',
				type: 'error',
				snackbarAutohide: null,
			}),
		);
		console.log('saga error', err);
	}
}

export function* setUserPassword(action: ReturnType<typeof UserActions.setUserPassword>) {
	try {
		yield call(UserService.getInstance().updateUserPassword, action.payload.requestBody);
		yield put(
			UserActions.setSnackbarMessage({
				message: 'Successfully set user password.',
				type: 'success',
				snackbarAutohide: null,
			}),
		);
		if (action.payload.success) {
			action.payload.success();
	}
	} catch (err) {
		if (action.payload.error) {
			action.payload.error();
	}
		yield put(
			UserActions.setSnackbarMessage({
				message: 'Failed to set user password. Please try again.',
				type: 'error',
				snackbarAutohide: null,
			}),
		);
		console.log('saga error', err);
	}
}

export function* getUserAuthFlags(action: ReturnType<typeof UserActions.getUserAuthFlags>) {
	try {
		const { data } = yield call(UserService.getInstance().getUserAuthFlags, action.payload.requestBody);
		action.payload.success(data);
	} catch (err) {
		action.payload.error(err);
		console.log('saga error', err);
	}
}

export function* requestCustomToken(action: ReturnType<typeof UserActions.requestCustomToken>) {
	try {
		const { data } = yield call(UserService.getInstance().requestCustomToken, action.payload.requestBody);
		action.payload.success(data.token);
	} catch (err) {
		action.payload.error(err);
		console.log('saga error', err);
	}
}
