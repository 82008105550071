import React from 'react';
import clsx from 'clsx';
import { Container, Paper, CssBaseline, FormControl, TextField, Button, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { BasePageStyles } from '../../hooks/styles';
import { IMAGES_URL } from '../../constants/Images';
import { useHistory } from 'react-router-dom';
import {passwordRegex } from '../users/users.helper';
import { UserActions } from '../../redux/actions';
import { connect } from 'react-redux';
import Loading from '../../components/loading';

const PasswordReset = (props: any) => {
  const history = useHistory();
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [passwordState, setPasswordState] = React.useState({
    lengthLimits: false,
    oneSpecialChar: false,
    oneNumber: false,
    oneUppercase: false,
    oneLowercase: false
  } as any);
  const [passwordFocused, setPasswordFocused] = React.useState(false)
  const [passwordConfirmFocused, setPasswordConfirmFocused] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = BasePageStyles();
  const paperStyles = {
    padding: '1em'
  }

  const passwordRequirements = () => (
    <>
      <span style={{ margin: '0.2em, auto' }}>
        <b>Password Requirements:</b><br></br>
        <span style={{ color: !passwordState.lengthLimits ? 'red' : 'green' }}>be between 8 and 255 characters</span><br></br>
        <span style={{ color: !passwordState.oneSpecialChar ? 'red' : 'green' }}>have at least 1 of the following characters: {`~!@#$%^&*_?`}</span><br></br>
        <span style={{ color: !passwordState.oneNumber ? 'red' : 'green' }}>have at least 1 number</span><br></br>
        <span style={{ color: !passwordState.oneUppercase ? 'red' : 'green' }}>have at least 1 uppercase letter</span><br></br>
        <span style={{ color: !passwordState.oneLowercase ? 'red' : 'green' }}>have at least 1 lowercase letter</span><br></br>
      </span>
    </>
  )

  const checkPasswordRequirement = (password: string) => {
    const result: any = {}
    Object.entries(passwordRegex).forEach((keyValue: any[]) => {
      result[keyValue[0]] = keyValue[1].test(password)
    })
    setPasswordState(result)
  }

  const handleTextFieldChange = (event: any, field: string) => {
    if (field === 'password') {
      checkPasswordRequirement(event.target.value)
      setPassword(event.target.value);
    }
    if (field === 'passwordConfirm') {
      setPasswordConfirm(event.target.value);
    }
  }

  const passwordIsValid = () => {
    return (password && 
    Object.values(passwordState).length > 0
    && !Object.values(passwordState).includes(false))
  }

  const passwordConfirmIsValid = () => {
    return (passwordConfirm && passwordConfirm === password)
  }

  const formIsValid = () => {
    if (!passwordIsValid()) {
      return false;
    }
    if (!passwordConfirmIsValid()) {
      return false;
    }

    return true;
  }

  const handleSubmit = () => {
    if (!formIsValid()) {
      return;
    }
    setIsLoading(true);
    props.setUserPassword({
      requestBody: {
        password: password,
      },
      success: () => {
        setTimeout(() => {
          setIsLoading(false);
          props.setAuthUser(null);
          sessionStorage.removeItem('force-password-reset');
          history.push('/');
        }, 1500);
      },
      error: () => {
        setIsLoading(false);
      }
    })
  }

  return (
    <div className={clsx(classes.root)}>
      <CssBaseline />
      <Container maxWidth="sm" className={classes.container}>
        <Paper style={paperStyles}>
          <img src={IMAGES_URL.RNI_LOGO} style={{ width: '50%', margin: '0 25%' }} alt="fdi" />
          <div style={{ textAlign: 'center' }}>
            <form noValidate autoComplete="off">
              <h4 style={{ marginBottom: '0.2em' }}>Set Your Password</h4>
              <FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
                <TextField
                  label="Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  error={(passwordFocused && !passwordIsValid()) ? true : false}
                  onFocus={() => setPasswordFocused(true)}
                  helperText={(passwordFocused && !passwordIsValid()) ? passwordRequirements() : ''}
                  onChange={(event) => { handleTextFieldChange(event, 'password') }}
                  required
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>,
                  }}
                />
              </FormControl>
              <br></br>
              <FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  value={passwordConfirm}
                  onBlur={() => setPasswordConfirmFocused(true)}
                  error={(passwordConfirmFocused && !passwordConfirmIsValid()) ? true : false}
                  helperText={(passwordConfirmFocused && !passwordConfirmIsValid()) ? <span style={{color: 'red'}}>Password confirmation doesn't match</span> : ''}
                  onChange={(event) => { handleTextFieldChange(event, 'passwordConfirm') }}
                  required
                />
              </FormControl>
              <br></br><br></br>
              {!isLoading && <FormControl style={{ margin: '0.5em', minWidth: '75%', textAlign: 'center' }}>
                <Button
                  type="submit"
                  style={{ margin: '10px 0 0 10px', backgroundColor: '#558db1 !important' }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}>
                  SUBMIT
                </Button>
              </FormControl>}
              {isLoading ? <Loading message="" /> : ''}
            </form>
          </div>
        </Paper>
      </Container>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  authUser: state.user.authUser
});

const mapDispatchToProps = (dispatch: any) => ({
  setUserPassword: (payload: any) => dispatch(UserActions.setUserPassword(payload)),
  setAuthUser: (payload: any) => dispatch(UserActions.setAuthUser(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset)