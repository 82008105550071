import Api from '../lib/api';
import { AUTH } from '../constants/api.url';
import { store } from '../store/store';

/*
 * user service also includes services related to personas and help videos
 */
export class UserService {
	private static instance: UserService;
	private callbackUrl: string;
	private constructor() {
		const state = store.getState() as { user: { callbackUrl: any } };
		this.callbackUrl = state.user.callbackUrl || '';
	}

	public static getInstance(): UserService {
		if (!UserService.instance) {
			UserService.instance = new UserService();
		}
		return UserService.instance;
	}

	public sendLoginEmail = async (emailAddress: string): Promise<any> => {
		await Api.post(`${AUTH.URL}/sendLoginEmail`, { email: emailAddress, callbackUrl: this.callbackUrl });
	};

	public verifyAccessCode = async (payload: {email: string, accessCode: string}): Promise<any> => {
		return await Api.post(`${AUTH.URL}/verifyAccessCode`, { ...payload, callbackUrl: this.callbackUrl });
	};

	public updateUserPassword = async (payload: {email: string, password: string}): Promise<any> => {
		return await Api.post(`${AUTH.URL}/updatePassword`, { ...payload, callbackUrl: this.callbackUrl });
	};

	public getUserAuthFlags = async (payload: {email: string}): Promise<any> => {
		return await Api.post(`${AUTH.URL}/getUserAuthFlags`, { ...payload, callbackUrl: this.callbackUrl });
	};

	public requestCustomToken = async (payload: {idToken: string}): Promise<any> => {
		return await Api.post(`${AUTH.URL}/requestCustomToken`, { ...payload, callbackUrl: this.callbackUrl });
	};
}
