import * as React from 'react';

import { Route, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import Logout from '../pages/logout';
import AuthError from '../pages/logout/auth-error';
import FourZeroFour from '../pages/login/404';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { themeNames } from '../hooks/styles/muiTheme';
import { getMuiTheme } from '../hooks/styles';
import { default as GlobalSnackbar } from '../components/snackbar';
import PasswordlessLogin from '../pages/login/passwordless-login';
import passwordReset from '../pages/login/password-reset';
import LoginScreen from '../pages/login/login-screen';
import { UserActions } from '../redux/actions';

/*
 * list the root of all valid routes here
 * so we can test for a definite 404 before user auth, etc
 */

const allExactRoutes = [
	//these routes accept no params
	'/logout',
	'/session-timeout',
	'/password-reset',
	'/passwordless-login',
	'/auth-error',
];

const isFourOhFour = () => {
	const path = window.location.pathname;

	if (path === '' || path === '/') {
		return false;
	}

	const isNotExactRoute = !allExactRoutes.some(r => {
		return path === r || path === r + '/';
	});

	return isNotExactRoute;
};

const handleCallbackUrl = (setCallbackUrl: Function, setAuthError: Function) => {
	const queryStringParams = new URLSearchParams(window.location.search);
	let callbackUrl = queryStringParams.get('callbackUrl');
	
	if (!callbackUrl) {
		const sessionCallbackUrl = sessionStorage.getItem('callbackUrl');
		if (!sessionCallbackUrl) {
			setAuthError('No callback URL provided');
			return false;
		}
		callbackUrl = sessionCallbackUrl;
	}

	setCallbackUrl(callbackUrl);
	sessionStorage.setItem('callbackUrl', callbackUrl as string);
	return true;
} 

const handleUrlFlags = () => {
	const queryStringParams = new URLSearchParams(window.location.search);
	if (queryStringParams.get('force-password-reset')) {
		sessionStorage.setItem('force-password-reset', 'true');
	}
}

const cleanUrl = () => {
	window.history.pushState({}, document.title, window.location.pathname);
}

const Routes = (props: any) => {
	const callbackUrlIsSet = handleCallbackUrl(props.setCallbackUrl, props.setAuthError);
	handleUrlFlags();
	cleanUrl();
	return (
		<MuiThemeProvider theme={getMuiTheme(props.selectedTheme || themeNames.LIGHT)}>
			<CssBaseline>
				<GlobalSnackbar />
				<Router>
					{isFourOhFour() && <Route component={FourZeroFour} />}
					{!callbackUrlIsSet && <Route component={AuthError} />}
					{!isFourOhFour() && callbackUrlIsSet && (
						<>
							<Route exact path="/password-reset" component={passwordReset} />
							<Route exact path="/logout" component={Logout} />
							<Route exact path="/passwordless-login" component={PasswordlessLogin} />
							<Route exact path="/auth-error" component={AuthError} />
							<Route exact path="/" component={LoginScreen} />
						</>
					)}
				</Router>
			</CssBaseline>
		</MuiThemeProvider>
	);
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  setCallbackUrl: (payload: string) => dispatch(UserActions.setCallbackUrl(payload)),
	setAuthError: (payload: string) => dispatch(UserActions.setAuthError(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
